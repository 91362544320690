import { CreditCardIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import {
  InvoiceStatus,
  OrganizationInvoiceQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'

type InvoicesTableActionRowProps = {
  invoice?: OrganizationInvoiceQueryResult
}

export const InvoicesTableActionRow = ({ invoice }: InvoicesTableActionRowProps) => {
  const { t } = useTranslation()

  return invoice?.status !== InvoiceStatus.Paid ? (
    <div className="flex justify-center">
      <Button asChild variant={Variant.Outline} size="sm" data-testid="btnInvoicePay">
        <Link to={Routes.Invoice} params={{ invoiceId: invoice?.invoiceNumber ?? '' }}>
          <CreditCardIcon className="mr-1 h-5 w-5" />
          {t('ssp:pages.invoices.list.actions.payInvoiceMobile')}
        </Link>
      </Button>
    </div>
  ) : null
}
