import {
  BuildingStorefrontIcon,
  ChartBarSquareIcon,
  ClipboardDocumentCheckIcon,
  CreditCardIcon,
  LifebuoyIcon,
  ListBulletIcon,
  Square2StackIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { ForwardRefExoticComponent, RefAttributes, SVGProps } from 'react'
import { useTranslation } from 'react-i18next'

import { Separator } from '@sherweb/core/components/Separator'
import { SidebarMenu as CoreSideBarMenu, SidebarMenuItem } from '@sherweb/core/components/Sidebar'
import { Permission } from '@sherweb/core/modules/authorization'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { usePermissionCheck } from '@ssp/modules/authorization'
import { useLocation, useNavigate, usePathGenerator } from '@ssp/modules/navigation'
import { useSelectedOrganization } from '@ssp/modules/organization'
import { useReseller, useResellerPortalSettings } from '@ssp/modules/reseller'

type MenuItems = {
  permission?: Permission
  label: string
  to: string
  hasChildPages?: boolean
  Icon?: ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, 'ref'> & {
      title?: string
      titleId?: string
    } & RefAttributes<SVGSVGElement>
  >
  dataTestId?: string
}

const Sidebar: React.FC = () => {
  const { data: portalSettings } = useResellerPortalSettings()

  const { data: resellerData } = useReseller()

  const { hasAccess } = usePermissionCheck()

  const { pathname } = useLocation()

  const { navigate } = useNavigate()

  const generatePath = usePathGenerator()

  const { t } = useTranslation()

  const organizationUniqueName = useSelectedOrganization()?.uniqueName ?? ''

  const onLogoClick = () => {
    navigate(Routes.Root)
  }

  const getOrganizationPath = (route: Routes) => generatePath(route, { organizationUniqueName })

  const menuItemsBeforeDivider: MenuItems[] =
    [
      ...(organizationUniqueName
        ? [
            {
              permission: Permission.DashboardAccess,
              label: t('ssp:layout.menu.dashboard'),
              to: getOrganizationPath(Routes.Dashboard),
              Icon: ChartBarSquareIcon,
              dataTestId: 'menuItemDashboard',
            },
            {
              permission: Permission.SubscriptionList,
              label: t('ssp:layout.menu.subscriptions'),
              to: getOrganizationPath(Routes.Subscriptions),
              Icon: ClipboardDocumentCheckIcon,
              hasChildPages: true,
              dataTestId: 'menuItemSubscriptions',
            },
            {
              permission: Permission.LicenseList,
              label: t('ssp:layout.menu.licenses'),
              to: getOrganizationPath(Routes.Licenses),
              Icon: Square2StackIcon,
              dataTestId: 'menuItemLicenses',
            },
            {
              label: t('ssp:layout.menu.marketplace'),
              permission: Permission.MarketplaceProductList,
              to: getOrganizationPath(Routes.Shop),
              Icon: BuildingStorefrontIcon,
              dataTestId: 'menuItemShop',
            },
            {
              permission: Permission.InvoicesList,
              label: t('ssp:layout.menu.invoices'),
              to: getOrganizationPath(Routes.Invoices),
              Icon: CreditCardIcon,
              dataTestId: 'menuItemInvoices',
            },
            {
              permission: Permission.HelpDeskList,
              label: t('ssp:layout.menu.support'),
              to: getOrganizationPath(Routes.HelpDesk),
              Icon: LifebuoyIcon,
              hasChildPages: true,
              dataTestId: 'menuItemSupport',
            },
          ]
        : []),
    ].filter(({ permission }) => !permission || hasAccess(permission)) ?? []

  const menuItemsAfterDivider = [
    {
      permission: Permission.UsersList,
      label: t('ssp:layout.menu.users'),
      to: getOrganizationPath(Routes.Users),
      Icon: UsersIcon,
      hasChildPages: true,
      dataTestId: 'menuItemUsers',
    },
    {
      permission: Permission.OrderList,
      label: t('ssp:layout.menu.orders'),
      to: getOrganizationPath(Routes.Orders),
      Icon: ListBulletIcon,
      hasChildPages: true,
      dataTestId: 'menuItemOrders',
    },
  ].filter(({ permission }) => !permission || hasAccess(permission))

  const isCurrent = (to: string, exact?: boolean) => {
    return exact ? to === pathname : pathname.startsWith(to)
  }

  return (
    <CoreSideBarMenu
      logoContainerClassName="bg-white"
      logo={portalSettings?.logo}
      companyName={resellerData?.name}
      onLogoClick={onLogoClick}
    >
      {menuItemsBeforeDivider.map(({ label, to, Icon, hasChildPages = false, dataTestId }) => (
        <Link key={to} to={to} className="block">
          <SidebarMenuItem
            dataTestId={dataTestId}
            Icon={Icon}
            current={isCurrent(to, !hasChildPages)}
          >
            {label}
          </SidebarMenuItem>
        </Link>
      ))}
      <Separator className="!my-4 h-px bg-slate-300" />
      {menuItemsAfterDivider.map(({ label, to, Icon, hasChildPages = false, dataTestId }) => (
        <Link key={to} to={to} className="block">
          <SidebarMenuItem
            dataTestId={dataTestId}
            Icon={Icon}
            current={isCurrent(to, !hasChildPages)}
          >
            {label}
          </SidebarMenuItem>
        </Link>
      ))}
    </CoreSideBarMenu>
  )
}

export default Sidebar
