import {
  InvoicesQuery,
  InvoiceQuerySort,
  InvoiceSortField,
  PaginatedListOfListOfOrganizationInvoiceQueryResult,
  OrganizationInvoiceQueryResult,
  InvoiceIntegrationType,
  InvoiceType,
  InvoiceStatus,
  BillingAddress,
  Money,
  Currency,
  InvoiceFormat,
  PaymentRestrictions,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class OrganizationInvoicesService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static queryOrganizationInvoicesAsync(
    params: {
      /**  */
      organizationId: string;
      /**  */
      query: InvoicesQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedListOfListOfOrganizationInvoiceQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/invoices/query';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['query'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
