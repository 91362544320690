import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Alert from '@sherweb/core/components/Alert'
import { InfiniteScroll } from '@sherweb/core/components/InfiniteScroll'
import { NoResults } from '@sherweb/core/components/NoResults'
import PageTitle, { PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import { Typography } from '@sherweb/core/components/Typography'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'

import { protectPage } from '@ssp/app/ProtectedPage'
import Routes from '@ssp/app/Routes'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import { useExactMatch } from '@ssp/modules/navigation'
import { useGetProductsFacetsQuery, useGetProductsQuery } from '@ssp/modules/shop/core/shop.hooks'

import { useGetSearchOptions } from './hooks'
import { ShopAllFilters } from './ShopAllFilters'
import { ShopProductsCard } from './ShopProductsCard'
import { ShopProductsLoader } from './ShopProductsLoader'
import { ShopSearch } from './ShopSearch'
import { ShopSort } from './ShopSort'

export const ShopPage = () => {
  const { t } = useTranslation()

  const searchOptions = useGetSearchOptions()

  const isProductDetailPage = useExactMatch(Routes.ShopProductDetail)

  const productsFacetsQuery = useGetProductsFacetsQuery({
    searchOptions,
    enabled: !isProductDetailPage,
  })

  const productsQuery = useGetProductsQuery({
    searchOptions,
    enabled: !isProductDetailPage && productsFacetsQuery.isSuccess,
  })

  const isProductsQueryLoading = isRequestLoading(productsQuery)

  const isProductFacetsLoading = productsFacetsQuery?.isLoading || !productsFacetsQuery?.data

  const hasNoResults =
    !isProductsQueryLoading && productsQuery?.data?.pages?.[0]?.foundProducts === 0

  const isError = productsFacetsQuery.isError || productsQuery.isError

  const handleFetchNextPage = async () => {
    await productsQuery.fetchNextPage()
  }

  const renderResult = () => {
    return hasNoResults ? (
      <NoResults dataTestId="Products" />
    ) : (
      <>
        {productsQuery?.data?.pages?.map(({ products }) =>
          products?.map(product => <ShopProductsCard key={product.id} product={product} />)
        )}
      </>
    )
  }

  return (
    <ContainedPage>
      <div className="py-4 sm:py-10">
        <PageTitleWrapper
          className="flex items-center"
          title={<PageTitle data-testid="pageShopTitle">{t('ssp:pages.shop.title')}</PageTitle>}
          subTitle={t('ssp:pages.shop.subTitle')}
        />
        <ShopSearch />
      </div>
      {isError ? (
        <Alert variant="danger" data-testid="errorLoadingResults">
          {t('ssp:errors.generic')}
        </Alert>
      ) : (
        <>
          <div className="my-4 flex w-full items-center justify-between">
            <Typography variant="heading5" weight="bold">
              {t('ssp:pages.shop.subHeading')}
            </Typography>
            <ShopSort isLoading={isProductsQueryLoading} />
          </div>
          <div className="flex w-full">
            <ShopAllFilters facets={productsFacetsQuery?.data} isLoading={isProductFacetsLoading} />
          </div>
          <div className="mb-4 mt-8 grid grid-cols-1 gap-4 lg:gap-6 xl:grid-cols-1">
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4">
              {isProductsQueryLoading ? <ShopProductsLoader /> : renderResult()}
            </div>
            {productsQuery?.hasNextPage ? (
              <InfiniteScroll
                isLoading={productsQuery.isFetchingNextPage}
                hasMore={productsQuery?.hasNextPage}
                next={handleFetchNextPage}
              />
            ) : null}
          </div>
        </>
      )}
      <Outlet />
    </ContainedPage>
  )
}

export default protectPage(ShopPage, Permission.MarketplaceProductList)
