import { useTranslation } from 'react-i18next'

import { OrganizationInvoiceQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import Card from '@sherweb/core/components/Card'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { InfiniteScroll } from '@sherweb/core/components/InfiniteScroll'
import { NoResultsMobile } from '@sherweb/core/components/NoResults/NoResultsMobile'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import {
  getAllPagesResults,
  isInfiniteScrollRequestHavingNoResults,
  isRequestLoading,
} from '@sherweb/core/modules/reactQuery'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { getInvoicesPaginationDefaultValues } from '@ssp/modules/invoices/core/invoices.helpers'
import { useGetInfiniteScrollInvoicessQuery } from '@ssp/modules/invoices/core/invoices.hooks'
import { InvoiceStatus } from '@ssp/modules/invoices/ui/InvoiceStatus'

import { InvoiceActions } from './InvoiceActions'

export const InvoicesTableMobile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const invoicesQuery = useGetInfiniteScrollInvoicessQuery(
    getInvoicesPaginationDefaultValues({
      searchTerm: searchText,
    })
  )

  const formatMoneyAmount = useMoneyFormatter(language)

  const noResults =
    isInfiniteScrollRequestHavingNoResults<OrganizationInvoiceQueryResult>(invoicesQuery)

  const invoices = getAllPagesResults<OrganizationInvoiceQueryResult>(invoicesQuery)

  const handleFetchNextPage = async () => {
    await invoicesQuery.fetchNextPage()
  }

  const formatDate = useDateFormatter(language, dateFormatTypes.numeric)

  const renderContent = () => {
    return noResults ? (
      <NoResultsMobile>{t('ssp:pages.invoices.noInvoice')}</NoResultsMobile>
    ) : (
      <>
        {invoices?.map(invoice => (
          <Accordion key={invoice.invoiceNumber} type="single" collapsible>
            <AccordionItem value={invoice.invoiceNumber ?? ''}>
              <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                #{invoice.invoiceNumber} - {formatMoneyAmount(invoice.amountDue)}
                <AccordionTriggerSubContent>
                  <InvoiceStatus invoiceStatus={invoice.status} />
                </AccordionTriggerSubContent>
              </AccordionTrigger>
              <AccordionContent>
                <AccordionContentList>
                  <AccordionContentListItem
                    label={t('ssp:pages.invoices.list.id')}
                    value={invoice.invoiceNumber}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.invoices.list.dueDate')}
                    value={formatDate(invoice.dueDate)}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.invoices.list.customer')}
                    value={invoice.externalClientName}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.invoices.list.total')}
                    value={formatMoneyAmount(invoice.amountDue)}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.invoices.list.outstanding')}
                    value={formatMoneyAmount(invoice.amountRemaining)}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.invoices.list.status')}
                    value={<InvoiceStatus invoiceStatus={invoice.status} />}
                  />
                  <AccordionContentListItem
                    className="justify-end"
                    labelClassName="flex flex-wrap"
                    label={<InvoiceActions invoice={invoice} className="flex flex-wrap" />}
                  />
                </AccordionContentList>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ))}
        {invoicesQuery?.hasNextPage ? (
          <InfiniteScroll
            isLoading={invoicesQuery.isFetchingNextPage}
            hasMore={invoicesQuery?.hasNextPage}
            next={handleFetchNextPage}
          />
        ) : null}
      </>
    )
  }
  return (
    <Card padded data-testid="mobileLayoutInvoices">
      <div className="flex flex-col gap-4">
        <DataTableMobileHeader>
          <DataTableMobileHeader.Input
            placeholder={t('ssp:pages.invoices.filterPlaceholder')}
            filteredText={searchText}
            setFilteredText={onSetSearchParams}
          />
        </DataTableMobileHeader>
        {isRequestLoading(invoicesQuery) ? <SkeletonTable /> : renderContent()}
      </div>
    </Card>
  )
}

export default InvoicesTableMobile
