import { useTranslation } from 'react-i18next'

import {
  InvoiceSortField,
  InvoiceStatus as InvoiceStatusType,
  OrganizationInvoiceQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import Card from '@sherweb/core/components/Card'
import DataTableServerPagination from '@sherweb/core/components/DataTable/DataTableServerPagination'
import { useServerPagination } from '@sherweb/core/components/DataTable/hooks/useServerPagination'
import { Columns } from '@sherweb/core/components/DataTable/types'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { getInvoicesPaginationDefaultValues } from '@ssp/modules/invoices/core/invoices.helpers'
import { useGetInvoicesQuery } from '@ssp/modules/invoices/core/invoices.hooks'
import { InvoiceStatus } from '@ssp/modules/invoices/ui/InvoiceStatus'

import { InvoicesTableActionRow } from './InvoicesTableActionRow'

const defaultMeta = { className: 'align-top text-center' }

const renderId = (invoiceId?: string) => <div className="font-medium">#{invoiceId}</div>

const renderStatus = (status?: InvoiceStatusType) => <InvoiceStatus invoiceStatus={status} />

const InvoicesTable = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { options, setOptions, onSortBy } = useServerPagination<InvoiceSortField>(
    getInvoicesPaginationDefaultValues()
  )

  const renderActionRowContent = (invoice?: OrganizationInvoiceQueryResult) => (
    <InvoicesTableActionRow invoice={invoice} />
  )

  const invoicesQuery = useGetInvoicesQuery(options)

  const formatMoneyAmount = useMoneyFormatter(language)

  const formatDate = useDateFormatter(language, dateFormatTypes.numeric)

  const columns: Columns<OrganizationInvoiceQueryResult> = [
    {
      accessorKey: 'id',
      meta: { ...defaultMeta },
      header: t('ssp:pages.invoices.list.id'),
      enableSorting: false,
      cell: ({ row }) => renderId(row.original.invoiceNumber),
    },
    {
      accessorKey: 'dueDate',
      meta: { ...defaultMeta },
      header: t('ssp:pages.invoices.list.dueDate'),
      enableSorting: false,
      cell: ({ row }) => formatDate(row.original.dueDate),
    },
    {
      accessorKey: 'customer',
      meta: { ...defaultMeta },
      header: t('ssp:pages.invoices.list.customer'),
      enableSorting: false,
      cell: ({ row }) => row.original.externalClientName,
    },
    {
      accessorKey: 'total',
      meta: { ...defaultMeta },
      header: t('ssp:pages.invoices.list.total'),
      enableSorting: false,
      cell: ({ row }) => formatMoneyAmount(row.original.amountDue),
    },
    {
      accessorKey: 'outstanding',
      meta: { ...defaultMeta },
      header: t('ssp:pages.invoices.list.outstanding'),
      enableSorting: false,
      cell: ({ row }) => formatMoneyAmount(row.original.amountRemaining),
    },
    {
      accessorKey: 'status',
      meta: { ...defaultMeta },
      header: t('ssp:pages.invoices.list.status'),
      enableSorting: false,
      cell: ({ row }) => renderStatus(row.original.status),
    },
    {
      id: 'actions',
      meta: { ...defaultMeta },
      enableSorting: false,
      cell: ({ row }) => renderActionRowContent(row.original),
      minSize: 20,
    },
  ]

  return (
    <Card padded>
      {isRequestLoading(invoicesQuery) ? (
        <SkeletonTable />
      ) : (
        <DataTableServerPagination
          columns={columns}
          data={invoicesQuery?.data}
          dataTestId="tableInvoices"
          isFetching={invoicesQuery.isFetching}
          emptyMessage={t('ssp:pages.invoices.noInvoice')}
          filterPlaceholder={t('ssp:pages.invoices.filterPlaceholder')}
          fieldDescription={t('ssp:pages.invoices.fieldDescription')}
          onSortBy={onSortBy}
          setPageParams={setOptions}
        />
      )}
    </Card>
  )
}

export default InvoicesTable
