import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { InvoicesQuery } from '@sherweb/core/openapi-generated/index.defs'

import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'

import { useSelectedOrganization } from '@ssp/modules/organization'

import { getOrganizationInvoicesQueryOptions } from './invoices.helpers'
import {
  queryOrganizationInvoicesAsync,
  queryOrganizationInvoicesAsyncWithInfiniteScroll,
} from './invoices.queries'

export const useGetInvoicesQuery = (
  queryOptions: InvoicesQuery = getOrganizationInvoicesQueryOptions()
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganization = useSelectedOrganization()

  return useQuery({
    queryKey: queryOrganizationInvoicesAsync.queryKey(selectedOrganization?.id, queryOptions),
    keepPreviousData: true,
    queryFn: async () =>
      await queryOrganizationInvoicesAsync.queryFn(selectedOrganization?.id, queryOptions),
    enabled: isLoggedIn && !!selectedOrganization?.id,
    staleTime: queryOrganizationInvoicesAsync.staleTime,
  })
}

export const useGetInfiniteScrollInvoicessQuery = (
  queryOptions: InvoicesQuery = getOrganizationInvoicesQueryOptions()
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganization = useSelectedOrganization()

  return useInfiniteQuery({
    queryKey: queryOrganizationInvoicesAsyncWithInfiniteScroll.queryKey(
      selectedOrganization?.id,
      queryOptions
    ),
    queryFn: async ({ pageParam = 1 }) =>
      await queryOrganizationInvoicesAsyncWithInfiniteScroll.queryFn(selectedOrganization?.id, {
        ...queryOptions,
        page: pageParam,
      }),
    enabled: isLoggedIn && !!selectedOrganization?.id,
    staleTime: queryOrganizationInvoicesAsyncWithInfiniteScroll.staleTime,
    getNextPageParam: lastPage => (lastPage?.hasNextPage ? lastPage.pageIndex + 1 : null),
  })
}
