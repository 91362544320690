import { InvoiceSortField, InvoicesQuery } from '@sherweb/core/openapi-generated/index.defs'

import { getDefaultParameters } from '@sherweb/core/components/DataTable/const'
import { Pagination } from '@sherweb/core/components/DataTable/types'

export const getInvoicesPaginationDefaultValues = (
  options?: Partial<Pagination<InvoiceSortField>>
) => {
  return getDefaultParameters<InvoiceSortField>({
    sort: {
      field: InvoiceSortField.CreatedOn,
      ascending: false,
    },
    ...options,
  })
}

export const getOrganizationInvoicesQueryOptions = (
  queryOptions?: Partial<Pagination<InvoiceSortField>>
): InvoicesQuery => {
  const paginationDefaultValues = getInvoicesPaginationDefaultValues()

  return {
    page: 1,
    pageSize: 20,
    sort: paginationDefaultValues?.sort,
    ...queryOptions,
  }
}
